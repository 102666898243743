import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ChartDataItem } from '../../../index';
import './index.scss';

interface PropsInterface {
  active: boolean;
  label: string;
  unit: string;
  payload?: ChartDataItem;
}

export const Tooltip: React.FC<PropsInterface> = ({ payload, active, label, unit }) => {
  const { t: tc } = useTranslation('common');

  return (
    <div className={classNames('tooltip', { 'tooltip--active': active })}>
      {label && <span className="tooltip__text tooltip__label">{label}</span>}
      {active && (
        <div className="tooltip__values">
          {payload?.values.map((el) => (
            <div key={el.type} className="tooltip__element">
              <span className="tooltip__color" style={{ backgroundColor: el.color }} />
              <span className="tooltip__text">
                {/* {el.type ? `L${el.type}: ` : null} */}
                {el.value ? `${el.value.toFixed(3)}${unit}` : tc('noData')}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

interface Payload {
  payload: ChartDataItem;
}

interface TooltipPropsInterface {
  active?: boolean;
  payload?: Payload[];
  setPayload: Dispatch<SetStateAction<ChartDataItem | undefined>>;
  setShowTooltip: Dispatch<SetStateAction<boolean>>;
}

export const CustomOverTooltip: React.FC<TooltipPropsInterface> = ({ active, payload, setPayload, setShowTooltip }) => {
  useEffect(() => {
    if (active && payload && payload.length) {
      setPayload(payload[0].payload);
      setShowTooltip(true);
    } else {
      setPayload(undefined);
      setShowTooltip(false);
    }
  }, [active, payload]);

  return null;
};
