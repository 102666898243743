import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlWrapper, CustomBackdrop, InputSelect, Summary } from '../../../../components';
import { ChannelInterface, ValueEntry } from '../../../../types';
import { AnalysisBarChart } from './components/chart';
import { RangeHeader } from './components/chart/range-header';
import { ANALYSIS_SELECTED_PHASES, useMeterAnalysis } from './hooks/use-meter-analysis';
import { getDataByRange } from './utils';

interface PropsInterface {
  channel: ChannelInterface;
}

export interface ChartDataItem {
  values: ChartDataValues[];
  time?: string;
  tooltipTime?: string;
  date?: number;
}

export interface ChartDataValues extends ValueEntry {
  color: string;
  empty?: boolean;
}

export const MeterAnalysis: React.FC<PropsInterface> = ({ channel }) => {
  const { i18n } = useTranslation();
  const {
    data,
    isLoading,
    mappedChartData,
    onClickActiveMeasurementRange,
    handleOnChange,
    activeAggregatedParameter,
    activeMeasurementRange,
    options,
    page,
    setPage,
    timeRanges,
  } = useMeterAnalysis({ channel });

  const chartData = useMemo(
    () => getDataByRange(activeMeasurementRange, mappedChartData, page, i18n.language),
    [activeMeasurementRange, mappedChartData, page, i18n.language],
  );

  return (
    <>
      <ControlWrapper className="control-wrapper--column  control-wrapper--reverse p-l-24 p-r-24 p-b-16">
        {options.length > 0 ? (
          <div className="grid-list-24">
            <InputSelect
              options={options}
              value={activeAggregatedParameter}
              onChange={handleOnChange}
              className="input--reverse"
              label={''}
            />
            <InputSelect
              options={timeRanges}
              value={activeMeasurementRange}
              onChange={onClickActiveMeasurementRange}
              className="input--reverse"
              label={''}
            />
          </div>
        ) : null}
        <RangeHeader
          prevAvailable={chartData.prevAvailable}
          nextAvailable={chartData.nextAvailable}
          range={chartData.range}
          setPage={setPage}
        />
        <AnalysisBarChart
          data={chartData.result}
          mappedChartData={mappedChartData}
          activeMeasurementRange={activeMeasurementRange}
          selectedPhases={ANALYSIS_SELECTED_PHASES}
          activeAggregatedParameter={activeAggregatedParameter}
          isLoading={isLoading}
        />
      </ControlWrapper>
      <Summary
        summaryData={chartData.result}
        activeMeasurementRange={activeMeasurementRange}
        selectedPhases={ANALYSIS_SELECTED_PHASES}
        activeAggregatedParameter={activeAggregatedParameter}
        hidePhases
      />
      <CustomBackdrop loading={isLoading && !data} />
    </>
  );
};
